import { replaceHtml } from '../utils/util';
import { getcellvalue } from '../global/getdata';
import { luckysheetrefreshgrid } from '../global/refresh';
import { rowLocation, colLocation, mouseposition } from '../global/location';
import formula from '../global/formula';
import tooltip from '../global/tooltip';
import editor from '../global/editor';
import { modelHTML } from './constant';
import { selectHightlightShow } from './select';
import server from './server';
import sheetmanage from './sheetmanage';
import luckysheetFreezen from './freezen';
import menuButton from './menuButton';
import method from '../global/method';
import { getSheetIndex } from '../methods/get';
import locale from '../locale/locale';
import Store from '../store';

const jwDataCtrl = {
    item: {
        ytCode:'',
        remark:'',
        zjwCode:'',
        checkOptions:'',
        type:'',
        authUnitTypes:'',
    },
    bindDatas: null,
    createDialog: function(){
        let _this = this;

        const _locale = locale();
        const hyperlinkText = _locale.insertLink;
        const toolbarText = _locale.toolbar;
        const buttonText = _locale.button;

        $("#luckysheet-modal-dialog-mask").show();
        $("#luckysheet-jwData-dialog").remove();

        let sheetListOption = '';
        Store.luckysheetfile.forEach(item => {
            sheetListOption += `<option value="${item.name}">${item.name}</option>`;
        })

        let content =  `<div class="box">
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-ytbs">云图标识：</label>
                                <input type="text" id="luckysheet-jwData-dialog-ytbs"/>
                                <span id="luckysheet-jwData-dialog-ytselect"><a>选择</a></span>
                            </div>
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-cqjwbs">建委标识：</label>
                                <input type="text" disabled id="luckysheet-jwData-dialog-cqjwbs"/>
                                <span id="luckysheet-jwData-dialog-jwselect"><a>选择</a></span>
                            </div>
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-desc">内容：</label>
                                <input type="text" id="luckysheet-jwData-dialog-desc"/>
                            </div>
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-Type">文本：</label>
                                <select id="luckysheet-jwData-dialog-Type">
                                    <option value="1">文本</option>
                                    <option value="2">日期</option>
                                    <option value="3">图片</option>
                                    <option value="4">签字</option>
                                    <option value="5">公司章</option>
                                    <option value="6">项目章</option>
                                    <option value="7">执业印章</option>
                                    <option value="8">见证取样章</option>
                                    <option value="9">复选框</option>
                                    <option value="10">签字、加盖执业印章</option value="10">
                                    <option value="11">签字人公司名称</option>
                                </select>
                            </div>
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-authunit">权限：</label>
                                <input type="text" id="luckysheet-jwData-dialog-authunit"/>
                            </div>
                            <div class="box-item">
                                <label for="luckysheet-jwData-dialog-linkTooltip">${hyperlinkText.linkTooltip}：</label>
                                <input type="text" id="luckysheet-jwData-dialog-linkTooltip" placeholder="${hyperlinkText.placeholder3}" />
                            </div>
                        </div>`;

        $("body").first().append(replaceHtml(modelHTML, {
            "id": "luckysheet-jwData-dialog", 
            "addclass": "luckysheet-jwData-dialog", 
            "title": '建委', 
            "content": content, 
            "botton":  `<button id="luckysheet-jwData-dialog-confirm" class="btn btn-primary">${buttonText.confirm}</button>
                        <button id="luckysheet-jwData-dialog-delete" class="btn btn-default">清除绑定</button>
                        <button class="btn btn-default luckysheet-model-close-btn">${buttonText.cancel}</button>`, 
            "style": "z-index:100003" 
        }));
        let $t = $("#luckysheet-jwData-dialog").find(".luckysheet-modal-dialog-content").css("min-width", 350).end(), 
            myh = $t.outerHeight(), 
            myw = $t.outerWidth();
        let winw = $(window).width(), 
            winh = $(window).height();
        let scrollLeft = $(document).scrollLeft(), 
            scrollTop = $(document).scrollTop();
        $("#luckysheet-jwData-dialog").css({ 
            "left": (winw + scrollLeft - myw) / 2, 
            "top": (winh + scrollTop - myh) / 3 
        }).show();

        _this.dataAllocation();
    },
    init: function (){
        let _this = this;
        const _locale = locale();
        const hyperlinkText = _locale.insertLink;
        
        //链接类型
        $(document).off("change.linkType").on("change.linkType", "#luckysheet-jwData-dialog-linkType", function(e){
            let value = this.value;

            $("#luckysheet-jwData-dialog .show-box").hide();
            $("#luckysheet-jwData-dialog .show-box-" + value).show();
        })

        //确认按钮
        $(document).off("click.confirm").on("click.confirm", "#luckysheet-jwData-dialog-confirm", function(e){
            let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
            let rowIndex = last.row_focus || last.row[0];
            let colIndex = last.column_focus || last.column[0];
            
            //文本
            let ytCode = $("#luckysheet-jwData-dialog-ytbs").val();
            let zjwCode = $("#luckysheet-jwData-dialog-cqjwbs").val();
            let checkOptions = $("#luckysheet-jwData-dialog-desc").val();
            let authUnitTypes = $("#luckysheet-jwData-dialog-authunit").val();
            let remark = $("#luckysheet-jwData-dialog-linkTooltip").val();
            let type = $("#luckysheet-jwData-dialog-Type").val();
            let item = {
                ytCode: ytCode,
                remark: remark,
                zjwCode:zjwCode,
                checkOptions:checkOptions,
                authUnitTypes:authUnitTypes,
                type:type,
            }

            let historyJwData = $.extend(true, {}, _this.bindDatas);
            let currentJwData = $.extend(true, {}, _this.bindDatas);

            currentJwData[rowIndex + "_" + colIndex] = item;
            let d = editor.deepCopyFlowData(Store.flowdata);
            let cell = d[rowIndex][colIndex];

            if(cell == null){
                cell = {};
            }

            // cell.fc = 'rgb(0, 0, 255)';
            // cell.un = 1;
            // cell.v = cell.m = linkText;

            d[rowIndex][colIndex] = cell;
            _this.ref(
                historyJwData, 
                currentJwData, 
                Store.currentSheetIndex, 
                d, 
                [{ row: [rowIndex, rowIndex], column: [colIndex, colIndex] }]
            );

            $("#luckysheet-modal-dialog-mask").hide();
            $("#luckysheet-jwData-dialog").hide();
        })
        //删除验证按钮
        $(document).off("click.delete").on("click.delete", "#luckysheet-jwData-dialog-delete", function(e) {
            let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
            let rowIndex = last.row_focus || last.row[0];
            let colIndex = last.column_focus || last.column[0];

            let historyJwData = $.extend(true, {}, _this.bindDatas);
            let currentJwData = $.extend(true, {}, _this.bindDatas);

            delete currentJwData[rowIndex + "_" + colIndex];
            let d = editor.deepCopyFlowData(Store.flowdata);
            let cell = d[rowIndex][colIndex];

            if(cell == null){
                cell = {};
            }
            // cell.fc = 'rgb(0, 0, 255)';
            // cell.un = 1;
            // cell.v = cell.m = linkText;

            d[rowIndex][colIndex] = cell;
            _this.ref(historyJwData, currentJwData, Store.currentSheetIndex,d, 
                [{ row: [rowIndex, rowIndex], column: [colIndex, colIndex] }]);
            $("#luckysheet-modal-dialog-mask").hide();
            $("#luckysheet-jwData-dialog").hide();
        });
        $(document).off('click.select').on('click.delete','#luckysheet-jwData-dialog-jwselect', function(e){
            method.createHookFunction("jwDataSelect");
        })
        $(document).off('click.select').on('click.delete','#luckysheet-jwData-dialog-ytselect', function(e){
            method.createHookFunction("ytDataSelect");
        })
    },
    dataAllocation: function(){
        let _this = this;

        let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
        let rowIndex = last.row_focus || last.row[0];
        let colIndex = last.column_focus || last.column[0];

        let bindDatas = _this.bindDatas || {};
        let item = bindDatas[rowIndex + "_" + colIndex] || {};
        $("#luckysheet-jwData-dialog-ytbs").val(item.ytCode);
        $("#luckysheet-jwData-dialog-cqjwbs").val(item.zjwCode);
        $("#luckysheet-jwData-dialog-desc").val(item.checkOptions);
        $("#luckysheet-jwData-dialog-authunit").val(item.authUnitTypes);
        let type = item.type || 1;
        $("#luckysheet-jwData-dialog-Type").val(type);
        //提示
        let remark = item.remark || '';
        $("#luckysheet-jwData-dialog-linkTooltip").val(remark);
    },
    cellFocus: function(r, c){
        let _this = this;

        if(_this.bindDatas == null || _this.bindDatas[r + '_' + c] == null){
            return;
        }

        let item = _this.bindDatas[r + '_' + c];

        if(item.linkType == 'external'){
            window.open(item.linkAddress);
        }
        else{
            let cellrange = formula.getcellrange(item.linkAddress);
            let sheetIndex = cellrange.sheetIndex;
            let range = [{
                row: cellrange.row,
                column: cellrange.column
            }];

            if(sheetIndex != Store.currentSheetIndex){
                $("#luckysheet-sheet-area div.luckysheet-sheets-item").removeClass("luckysheet-sheets-item-active");
                $("#luckysheet-sheets-item" + sheetIndex).addClass("luckysheet-sheets-item-active");

                sheetmanage.changeSheet(sheetIndex);
            }

            Store.luckysheet_select_save = range;
            selectHightlightShow(true);

            let row_pre = cellrange.row[0] - 1 == -1 ? 0 : Store.visibledatarow[cellrange.row[0] - 1];
            let col_pre = cellrange.column[0] - 1 == -1 ? 0 : Store.visibledatacolumn[cellrange.column[0] - 1];

            $("#luckysheet-scrollbar-x").scrollLeft(col_pre);
            $("#luckysheet-scrollbar-y").scrollTop(row_pre);
        }
    },
    overshow: function(event){
        let _this = this;

        $("#luckysheet-jwdata-overshow").remove();

        if($(event.target).closest("#luckysheet-cell-main").length == 0){
            return;
        }

        let mouse = mouseposition(event.pageX, event.pageY);
        let scrollLeft = $("#luckysheet-cell-main").scrollLeft();
        let scrollTop = $("#luckysheet-cell-main").scrollTop();
        let x = mouse[0] + scrollLeft;
        let y = mouse[1] + scrollTop;

        if(luckysheetFreezen.freezenverticaldata != null && mouse[0] < (luckysheetFreezen.freezenverticaldata[0] - luckysheetFreezen.freezenverticaldata[2])){
            return;
        }

        if(luckysheetFreezen.freezenhorizontaldata != null && mouse[1] < (luckysheetFreezen.freezenhorizontaldata[0] - luckysheetFreezen.freezenhorizontaldata[2])){
            return;
        }

        let row_index = rowLocation(y)[2];
        let col_index = colLocation(x)[2];

        let margeset = menuButton.mergeborer(Store.flowdata, row_index, col_index);
        if(!!margeset){
            row_index = margeset.row[2];
            col_index = margeset.column[2];
        }

        if(_this.bindDatas == null || _this.bindDatas[row_index + "_" + col_index] == null){
            return;
        }

        let item = _this.bindDatas[row_index + "_" + col_index];
        let linkTooltip = item.ytCode;

        // if(linkTooltip == null || linkTooltip.replace(/\s/g, '') == ''){
        //     linkTooltip = item.linkAddress;
        // }

        let row = Store.visibledatarow[row_index], 
            row_pre = row_index - 1 == -1 ? 0 : Store.visibledatarow[row_index - 1];
        let col = Store.visibledatacolumn[col_index], 
            col_pre = col_index - 1 == -1 ? 0 : Store.visibledatacolumn[col_index - 1];

        if(!!margeset){
            row = margeset.row[1];
            row_pre = margeset.row[0];
            
            col = margeset.column[1];
            col_pre = margeset.column[0];
        }

        let html = `<div id="luckysheet-jwdata-overshow" style="background:#fff;padding:5px 10px;border:1px solid #000;box-shadow:2px 2px #999;position:absolute;left:${col_pre}px;top:${row + 5}px;z-index:100;">
                        <div>云图：${item.ytCode}</div>
                        <div>建委：${item.zjwCode?item.zjwCode:'暂无'}</div>
                        <div>提示：${item.remark}</div>
                    </div>`;

        $(html).appendTo($("#luckysheet-cell-main"));
    },
    ref: function(historyJwData, currentJwData, sheetIndex, d, range){
        let _this = this;

        if (Store.clearjfundo) {
            Store.jfundo.length  = 0;

            let redo = {};
            redo["type"] = "updateJwData";
            redo["sheetIndex"] = sheetIndex;
            redo["historyJwData"] = historyJwData;
            redo["currentJwData"] = currentJwData;
            redo["data"] = Store.flowdata; 
            redo["curData"] = d;
            redo["range"] = range; 
            Store.jfredo.push(redo); 
        }

        _this.bindDatas = currentJwData;
        Store.luckysheetfile[getSheetIndex(sheetIndex)].bindDatas = currentJwData;

        Store.flowdata = d;
        editor.webWorkerFlowDataCache(Store.flowdata);//worker存数据
        Store.luckysheetfile[getSheetIndex(sheetIndex)].data = Store.flowdata;

        //共享编辑模式
        if(server.allowUpdate){ 
            server.saveParam("all", sheetIndex, currentJwData, { "k": "bindDatas" });
            server.historyParam(Store.flowdata, sheetIndex, range[0]);
        }

        setTimeout(function () {
            luckysheetrefreshgrid();
        }, 1);
    },
    insert: function(value){
        $("#luckysheet-jwData-dialog-cqjwbs").val(value);
    },
    ytInsert: function(e) {
        $("#luckysheet-jwData-dialog-ytbs").val(e.code);
        $("#luckysheet-jwData-dialog-authunit").val(e.power);
        $("#luckysheet-jwData-dialog-linkTooltip").val(e.name);
        $("#luckysheet-jwData-dialog-Type").val(e.inputType);
        $("#luckysheet-jwData-dialog-desc").val(e.content);
        if (e.zjwCode) {
            $("#luckysheet-jwData-dialog-cqjwbs").val(e.zjwCode);
        }else{
            $("#luckysheet-jwData-dialog-cqjwbs").val("");
        }
    }
}

export default jwDataCtrl;