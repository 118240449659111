import { getObjType } from '../utils/util';
import { isRealNull, isRealNum, valueIsError } from './validate';
import { genarate, update } from './format';
import server from '../controllers/server';
import luckysheetConfigsetting from '../controllers/luckysheetConfigsetting';
import Store from '../store/index'
import menuButton from '../controllers/menuButton';
import { getColumnAndRowSize } from '../controllers/updateCell';
//Set cell value
function setcellvalue(r, c, d, v, file) { // 扩张第五个参数 解决字号
    if(d == null){
        d = Store.flowdata;
    }
    // 若采用深拷贝，初始化时的单元格属性丢失
    // let cell = $.extend(true, {}, d[r][c]);
    let cell = d[r][c];

    let vupdate;

    if(getObjType(v) == "object"){
        if(cell == null){
            cell = v;
        }
        else{
            if(v.f != null){
                cell.f = v.f;
            }else if(cell.hasOwnProperty('f')){
                delete cell.f;
            }

            if(v.spl != null){
                cell.spl = v.spl;
            }

            if(v.ct != null){
                cell.ct = v.ct;
            }
        }


        if(getObjType(v.v) == "object"){
            vupdate = v.v.v;
        }
        else{
            vupdate = v.v;
        }
    }
    else{
        vupdate = v;
    }

    if(isRealNull(vupdate)){
        if(getObjType(cell) == "object"){
            delete cell.m;
            delete cell.v;
        }
        else{
            cell = null;
        }

        d[r][c] = cell;

        return;
    }

    // 1.为null
    // 2.数据透视表的数据，flowdata的每个数据可能为字符串，结果就是cell == v == 一个字符串或者数字数据
    if(isRealNull(cell) || (getObjType(cell) === 'string' || getObjType(cell) === 'number') && cell === v){
        cell = {};
    }

    let vupdateStr = vupdate.toString();

    if(vupdateStr.substr(0, 1) == "'"){
        cell.m = vupdateStr.substr(1);
        cell.ct = { "fa": "@", "t": "s" };
        cell.v = vupdateStr.substr(1);
        cell.qp = 1;
    }
    else if(cell.qp == 1){
        cell.m = vupdateStr;
        cell.ct = { "fa": "@", "t": "s" };
        cell.v = vupdateStr;
        /** @guoqing size auto*/
        const { size } = tableSizeFunc(file, r, c, cell, d, vupdateStr);
        if (size > 0) {
            cell.fs = size;
        }
    }
    else if(vupdateStr.toUpperCase() === "TRUE"){
        cell.m = "TRUE";
        cell.ct = { "fa": "General", "t": "b" };
        cell.v = true;
    }
    else if(vupdateStr.toUpperCase() === "FALSE"){
        cell.m = "FALSE";
        cell.ct = { "fa": "General", "t": "b" };
        cell.v = false;
    }
    else if(vupdateStr.substr(-1) === "%" && isRealNum(vupdateStr.substring(0, vupdateStr.length-1))){
            cell.ct = {fa: "0%", t: "n"};
            cell.v = vupdateStr.substring(0, vupdateStr.length-1) / 100;
            cell.m = vupdate;
    }
    else if(valueIsError(vupdate)){
        cell.m = vupdateStr;
        // cell.ct = { "fa": "General", "t": "e" };
        if(cell.ct!=null){
            cell.ct.t = "e";
        }
        else{
            cell.ct = { "fa": "General", "t": "e" };
        }
        cell.v = vupdate;
    }
    else{
        if(cell.f != null && isRealNum(vupdate) && !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(vupdate)){
            cell.v = parseFloat(vupdate);
            if(cell.ct==null){
                cell.ct = { "fa": "General", "t": "n" };
            }

            if(cell.v == Infinity || cell.v == -Infinity){
                cell.m = cell.v.toString();
            }
            else{
                if(cell.v.toString().indexOf("e") > -1){
                    let len;
                    if(cell.v.toString().split(".").length==1){
                        len = 0;
                    }
                    else{
                        len = cell.v.toString().split(".")[1].split("e")[0].length;
                    }
                    if(len > 5){
                        len = 5;
                    }

                    cell.m = cell.v.toExponential(len).toString();
                }
                else{
                    let v_p = Math.round(cell.v * 1000000000) / 1000000000;
                    if(cell.ct==null){
                        let mask = genarate(v_p);
                        cell.m = mask[0].toString();
                    }
                    else{
                        let mask = update(cell.ct.fa, v_p);
                        cell.m = mask.toString();
                    }

                    // cell.m = mask[0].toString();
                }
            }
        }
        else if(cell.ct != null && cell.ct.fa == "@"){
            cell.m = vupdateStr;
            cell.v = vupdate;
        }
        else if(cell.ct != null && cell.ct.fa != null && cell.ct.fa != "General"){
            if(isRealNum(vupdate)){
                vupdate = parseFloat(vupdate);
            }

            let mask = update(cell.ct.fa, vupdate);

            if(mask === vupdate){ //若原来单元格格式 应用不了 要更新的值，则获取更新值的 格式
                mask = genarate(vupdate);

                cell.m = mask[0].toString();
                cell.ct = mask[1];
                cell.v = mask[2];
            }
            else{
                cell.m = mask.toString();
                cell.v = vupdate;
            }
        }
        else{
            if(isRealNum(vupdate) && !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(vupdate)){

                if(typeof vupdate === "string"){
                    let flag = vupdate.split("").every(ele=>ele == "0" || ele == ".");
                    if(flag){
                        vupdate = parseFloat(vupdate);
                    }
                }
                cell.v = vupdate;   /* 备注：如果使用parseFloat，1.1111111111111111会转换为1.1111111111111112 ? */
                cell.ct = { "fa": "General", "t": "n" };
                if(cell.v == Infinity || cell.v == -Infinity){
                    cell.m = cell.v.toString();
                }
                else{
                    let mask = genarate(cell.v);
                    cell.m = mask[0].toString();
                }
            }
            else{
                let mask = genarate(vupdate);
                /** @guoqing size auto*/
                const { size } = tableSizeFunc(file, r, c, cell, d, mask[2]);
                if (size > 0) {
                    cell.fs = size;
                }
                cell.m = mask[0].toString();
                cell.ct = mask[1];
                cell.v = mask[2];
            }
        }
    }
    if(!server.allowUpdate && !luckysheetConfigsetting.pointEdit){
        if(cell.ct != null && /^(w|W)((0?)|(0\.0+))$/.test(cell.ct.fa) == false && cell.ct.t == "n" && cell.v != null && parseInt(cell.v).toString().length > 4){
            let autoFormatw = luckysheetConfigsetting.autoFormatw.toString().toUpperCase();
            let accuracy = luckysheetConfigsetting.accuracy;

            let sfmt = setAccuracy(autoFormatw, accuracy);

            if(sfmt != "General") {
                cell.ct.fa = sfmt;
                cell.m = update(sfmt, cell.v);
            }
        }
    }

    d[r][c] = cell;
}
/**
* @guoqing扩展部分
*/
function tableSizeFunc(file, r, c, cell, d, str) {
    let size;
    if (file) {
        size = getColumnAndRowSizeKuozhan(file, r, c, cell);
    } else {
        if ($("#luckysheet-input-box").attr("edit") == 1) {
            size = getColumnAndRowSize(r,c,d);
        }
    }
    if (file && file.config.columnlen || $("#luckysheet-input-box").attr("edit") == 1 && size.col_index !=0) {
        $("#query-cell-width").html(str)
        let row = size.row, 
        row_pre = size.row_pre, 
        col = size.col, 
        col_pre = size.col_pre;
        let value = ((row - row_pre + 1- 4)/14) / ($("#query-cell-width").width() / (col - col_pre+ 1- 8));
        // console.log(value, str)
        if (value < 1.5 && value > 0.9) {
            // cell.fs = 9;
            return {
                size: 9
            }
        }
        if (value < 0.9 && value > 0.7) {
            // cell.fs = 8;
            return {
                size: 8
            }
        }
        if (value < 0.7 && value > 0.55) {
            // cell.fs = 7;
            return {
                size: 7
            }
        }
        if (value < 0.55) {
            // cell.fs = 6;
            return {
                size: 6
            }
        }
        return {
            size: -1
        }
    }
    return {
        size: -1
    }
}
function getColumnAndRowSizeKuozhan(file, row_index, col_index, cell){
    let row = visibledatarow(file, file.row+1)[row_index], 
        row_pre = row_index - 1 == -1 ? 0 : visibledatarow(file, file.row+1)[row_index - 1];
    let col = visibledatacolumn(file, file.column+1)[col_index], 
        col_pre = col_index - 1 == -1 ? 0 : visibledatacolumn(file, file.column+1)[col_index - 1];

    // if(d == null){
    //     d = Store.flowdata;
    // }

    let margeset = menuButtonMergeborer(cell, row_index, col_index, visibledatarow(file, file.row+1), visibledatacolumn(file, file.column+1));
    if(!!margeset){
        
        row = margeset.row[1];
        row_pre = margeset.row[0];
        row_index = margeset.row[2];
        col = margeset.column[1];
        col_pre = margeset.column[0];
        col_index = margeset.column[2];
    }    

    return {
        row: row,
        row_pre: row_pre,
        row_index: row_index,
        col: col,
        col_pre: col_pre,
        col_index: col_index
    }
}
function menuButtonMergeborer(cell, row_index, col_index, visibledatarow, visibledatacolumn){
    // if(d==null || d[row_index]==null){
    //     console.warn("Merge info is null", row_index, col_index);
    //     return null;
    // }
    let value = cell;
    
    if(getObjType(value) == "object" && ("mc" in value)){
        let margeMaindata = value["mc"];
        if(margeMaindata==null){
            console.warn("Merge info is null", row_index, col_index);
            return null;
        }
        col_index = margeMaindata.c;
        row_index = margeMaindata.r;


        // if(cell==null){
        //     console.warn("Main merge Cell info is null", row_index, col_index);
        //     return null;
        // }
        let col_rs = cell.mc.cs;
        let row_rs = cell.mc.rs;

        let margeMain = cell.mc;
        
        let start_r, end_r, row, row_pre;
        for(let r = row_index; r < margeMain.rs + row_index; r++){
            if (r == 0) {
                start_r = - 1;
            }
            else {
                start_r = visibledatarow[r - 1] - 1;
            }

            end_r = visibledatarow[r];

            if(row_pre == null){
                row_pre = start_r;
                row = end_r;
            }
            else{
                row += end_r - start_r - 1;
            }
        }

        let start_c, end_c, col, col_pre; 
        for(let c = col_index; c < margeMain.cs + col_index; c++){
            if (c == 0) {
                start_c = 0;
            }
            else {
                start_c = visibledatacolumn[c - 1];
            }

            end_c = visibledatacolumn[c];
            if(col_pre == null){
                col_pre = start_c;
                col = end_c;
            }
            else{
                col += end_c - start_c;
            }
        }

        return {
            "row": [row_pre , row, row_index, row_index + row_rs - 1], 
            "column": [col_pre, col , col_index, col_index + col_rs - 1]
        };
    }
    else{
        return null;
    }
}
//new runze 根据亿万格式autoFormatw和精确度accuracy 转换成 w/w0/w0.00 or 0/0.0格式
function setAccuracy(autoFormatw, accuracy) {
    let acc = "0.";
    let fmt;

    if(autoFormatw == "TRUE"){
        if(accuracy == undefined){
            return "w";
        }
        else{
            let alength = parseInt(accuracy);

            if(alength == 0){
                return "w0";
            }
            else{
                acc = "w0.";

                for(let i = 0; i < alength; i++){
                    acc += "0";
                }

                fmt = acc;
            }
        }
    }
    else{
        if(accuracy == undefined){
            return "General";
        }
        else{
            let alength = parseInt(accuracy);

            if(alength == 0){
                return "0";
            }
            else{
                for(let i = 0; i < alength; i++){
                    acc += "0";
                }

                fmt = acc;
            }

        }
    }

    return fmt.toString();
}
function visibledatarow(file, rowheight) {
    //行高
    if(rowheight != null){
        let visibledatarow = [];
        let rh_height = 0;

        for (let r = 0; r < rowheight; r++) {
            let rowlen = Store.defaultrowlen;

            if (file.config["rowlen"] != null && file.config["rowlen"][r] != null) {
                rowlen = file.config["rowlen"][r];
            }


            rh_height += Math.round((rowlen + 1) * file.zoomRatio);

            visibledatarow.push(rh_height); //行的临时长度分布
        }
        return visibledatarow;
    }
}
function visibledatacolumn(file, colwidth) {
    if(colwidth != null){
        let visibledatacolumn = [];
        let ch_width = 0;
    
        let maxColumnlen = 120;
    
        for (let c = 0; c < colwidth; c++) {
            let firstcolumnlen = Store.defaultcollen;
    
            if (file.config["columnlen"] != null && file.config["columnlen"][c] != null) {
                firstcolumnlen = file.config["columnlen"][c];
            }
            else {
                if (Store.flowdata[0] != null && Store.flowdata[0][c] != null) {
                    if (firstcolumnlen > 300) {
                        firstcolumnlen = 300;
                    }
                    else if (firstcolumnlen < Store.defaultcollen) {
                        firstcolumnlen = Store.defaultcollen;
                    }
    
                    if (firstcolumnlen != Store.defaultcollen) {
                        if (file.config["columnlen"] == null) {
                            file.config["columnlen"] = {};
                        }
    
                        file.config["columnlen"][c] = firstcolumnlen;
                    }
                }
            }
    
            if(file.config["colhidden"] != null && file.config["colhidden"][c] != null){
                visibledatacolumn.push(ch_width);
                continue;
            }
            ch_width += Math.round((firstcolumnlen + 1)*file.zoomRatio);
    
            visibledatacolumn.push(ch_width);//列的临时长度分布
    
            // if(maxColumnlen < firstcolumnlen + 1){
            //     maxColumnlen = firstcolumnlen + 1;
            // }
        }
        return visibledatacolumn;
    }
}
export {
    setcellvalue,
    setAccuracy,
}
